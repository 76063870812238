import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import StepWindow from "../../@core/components/captcha"
import Question from "../../@core/components/Question"

const Connect = () => {
    const [submitData, setSubmitData] = useState({})

    const handleSubmit = (e) => {
        console.log(submitData)
        e.preventDefault()
        if (!submitData.fullName || !submitData.email || !submitData.subject || !submitData.message) {
            toast.error("Please fill all the fields")
            return
        }
        console.log(submitData)
        toast.success("Submit Successfully")
        setSubmitData({})
        const form = e.target
        form.reset()
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
        >
            <h2 className="text-center mt-6 mb-12 font-[Roboto] font-[600] text-[40px]">Contact Me</h2>
            <div className="px-5 mx-auto max-w-7xl">
                <div className="grid grid-cols-1 pb-5 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-36">
                    <div className="flex flex-col self-stretch justify-between">
                        <div className="w-full rounded-xl overflow-hidden mb-8 h-[400px]">
                            <iframe
                                title="Location Map"
                                width="100%"
                                height="500"
                                id="gmap_canvas"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2164.6726843740607!2d-2.120444484364832!3d57.142657791410834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x488411d85ecf66bd%3A0xbc58dcc43cedd8af!2sAlbyn%20Hospital!5e0!3m2!1sen!2sin!4v1679981495100!5m2!1sen!2sin"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                            ></iframe>
                        </div>

                        <div className="grid grid-cols-1 gap-8 mt-12 sm:grid-cols-2 lg:mt-auto">
                            <div>
                                <h3 className="text-sm flex items-center font-medium tracking-widest text-[#0ABAB5] uppercase">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>

                                    <span className="text-[#2F2F2F] ml-[10px]">Clinic Time</span>
                                </h3>
                                <p className="mt-5 text-sm text-[#61748E]">
                                    Tuesday
                                    <br />9 AM to 12 noon
                                </p>
                            </div>

                            <div>
                                <h3 className="text-sm flex items-center font-medium tracking-widest text-[#0ABAB5] uppercase">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                        />
                                    </svg>

                                    <span className="text-[#2F2F2F] ml-[10px]">Get In Touch</span>
                                </h3>

                                <p className="mt-5 text-sm text-[#61748E]">Secretary: 0808 189 5654</p>
                            </div>

                            <div>
                                <h3 className="text-sm flex items-center font-medium tracking-widest text-[#0ABAB5] uppercase">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                        />
                                    </svg>

                                    <span className="text-[#2F2F2F] ml-[10px]">Email</span>
                                </h3>
                                <p className="mt-5 text-sm text-[#61748E]">info@painc.co.uk</p>
                            </div>

                            <div>
                                <h3 className="text-sm flex items-center font-medium tracking-widest text-[#0ABAB5] uppercase">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                        />
                                    </svg>

                                    <span className="text-[#2F2F2F] ml-[10px]">Our Address</span>
                                </h3>
                                <p className="mt-5 text-sm text-[#61748E]">
                                    Albyn Hospital, 21-24, Albyn Place, Aberdeen AB10 1RW
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            border: "1px solid #E2E8F0",
                            boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.06), 0px 10px 15px rgba(0, 0, 0, 0.03)"
                        }}
                        className="bg-white rounded-2xl"
                    >
                        <div className="p-6 sm:p-10">
                            <form
                                onSubmit={handleSubmit}
                                className="space-y-6"
                            >
                                <div>
                                    <label
                                        htmlFor="fullName"
                                        className="text-base font-medium text-gray-900"
                                    >
                                        {" "}
                                        Your name{" "}
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="fullName"
                                            id="fullName"
                                            onChange={(e) => setSubmitData({ ...submitData, fullName: e.target.value })}
                                            placeholder="Enter your full name"
                                            className="block w-full px-4 py-4 text-base text-gray-900 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label
                                        htmlFor="email"
                                        className="text-base font-medium text-gray-900"
                                    >
                                        {" "}
                                        Email address{" "}
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={(e) => setSubmitData({ ...submitData, email: e.target.value })}
                                            placeholder="Enter your email"
                                            className="block w-full px-4 py-4 text-base text-gray-900 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label
                                        htmlFor="email"
                                        className="text-base font-medium text-gray-900"
                                    >
                                        Subject
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="subject"
                                            onChange={(e) => setSubmitData({ ...submitData, subject: e.target.value })}
                                            placeholder="Enter Subject Here"
                                            className="block w-full px-4 py-4 text-base text-gray-900 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label
                                        htmlFor="message"
                                        className="text-base font-medium text-gray-900"
                                    >
                                        {" "}
                                        Write your message{" "}
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            onChange={(e) => setSubmitData({ ...submitData, message: e.target.value })}
                                            name="message"
                                            id="message"
                                            placeholder="Write us your question here..."
                                            rows="4"
                                            className="block w-full px-4 py-4 text-base text-gray-900 bg-white border border-gray-200 resize-y rounded-xl focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
                                        ></textarea>
                                    </div>
                                </div>
                                <StepWindow />

                                <button
                                    type="submit"
                                    className="inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 text-white transition-all duration-200 bg-[#0ABAB5] border border-transparent rounded hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                >
                                    Send message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <Question />
            </div>
        </div>
    )
}

export default Connect
