/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

// ** import third party
import { Outlet, useLocation } from "react-router-dom";
import Advice from "../../@core/components/Advice";
import Footer from "../../@core/layouts/Footer";

// ** import components
import Navbar from "../../@core/layouts/Navbar";

// ** import routes meta data
import { Routes as metaData } from "../../router/routes";

const Horizontal = () => {
  // ** get location
  let location = useLocation(); // it's really important for get updated metaData

  // ** States
  const [meta, setMeta] = useState(null);

  const handleSetMeta = () => {
    const { meta } = metaData.filter((item) => {
      if (location.pathname === item.path || item.path.endsWith(":id")) {
        return item.meta;
      }
    })[0];
    setMeta(meta);
  };

  //** ComponentDidMount
  useEffect(() => {
    handleSetMeta();
  }, [location]);

  return (
    <div>
      {(meta?.layout !== "blank" || meta?.layout === undefined) && meta && (
        <div>
          {meta?.isNotMenu ? null : <Navbar />}
          <Outlet />
          {meta?.isNotAdvice ? null : <Advice />}
          {meta?.isNotFooter ? null : <Footer />}
        </div>
      )}
    </div>
  );
};

export default Horizontal;
