// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

export const dateFormatter = (date) => {
  if(!date) {
    return
  }
  const makeDate = new Date(date);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const finalDate = makeDate.toLocaleDateString("en-US", options);
  return finalDate;
};
