import React from "react";
import { RightArrow } from "../../../assets/svg/arrow";
import { dateFormatter } from "../../../utils";

const SingalService = ({
  index,
  number,
  title,
  date,
  directLink,
  link,
  pubDate,
}) => {
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
  const formattedDate = dateFormatter(date);
  return (
    <div
      data-aos="fade-up"
      style={{
        boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)",
        border: "1px solid #F3F3F3",
      }}
      className="flex flex-col mt-10 w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[30px] px-[25px] mx-auto"
    >
      {number && (
        <div className="bg-[#0ABAB5] w-[40px] h-[40px] font-[Roboto] flex items-center justify-center rounded-full text-white">
          {number}
        </div>
      )}
      {(formattedDate || pubDate) && (
        <p className="text-xs text-[#7F7F9B]">
          {formattedDate ? formattedDate : pubDate}
        </p>
      )}
      <h3 className="font-[600] text-[18px] font-[Roboto] mt-3 h-16  ">
        {title?.length > 50 ? title.substring(0, 50) + "..." : title}
      </h3>
      <a
        target="_blank"
        className="mt-3 font-[Roboto] flex text-[#0ABAB5]"
        href={directLink ? link : frontendUrl + link}
        rel="noreferrer"
      >
        Read More <RightArrow className="ml-2 !w-5 !h-5" />{" "}
      </a>
    </div>
  );
};

export default SingalService;
