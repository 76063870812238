import React from "react"
import HeaderImg from "../../../assets/images/Header.png"
import HeaderImg2 from "../../../assets/images/about/mainImg.png"
import star from "../../../assets/images/star.png"

const Header = ({ page }) => {
    return (
        <section className="relative py-12 sm:py-16 lg:pb-40 !z-10 ">
            <div className="relative px-8 ms:px-0 mx-auto max-w-7xl">
                <div className="grid grid-cols-1 gap-y-4 lg:items-center lg:grid-cols-2 xl:grid-cols-2">
                    <div className="w-[90%] sm:w-[80%] md:w-[50%] mx-auto lg:w-[auto] xl:col-span-1 lg:pr-20 relative">
                        <img
                            className="w-full mx-auto"
                            src={page === "aboutus" ? HeaderImg2 : HeaderImg}
                            alt=""
                        />
                        <div className="absolute top-[84%] left-[68%]">
                            <img
                                src={star}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="text-center xl:col-span-1 lg:text-left md:px-16 mt-10 lg:mt-0 lg:px-0">
                        <h1 className="text-3xl font-bold leading-tight sm:!leading-[55px] lg:w-[90%] lg:text-4xl text-[#1F3636] font-pj">
                            Dr Saravana Kanakarajan <br />
                            Consultant in Pain Medicine MBBS, MD, FCPS, FRCA, FFPMRCA
                        </h1>

                        <a
                            href="https://www.circlehealthgroup.co.uk/online-booking?consultant=C5203328"
                            title=""
                            target="_blank"
                            className="inline-flex px-8 py-3 mt-8 text-lg font-bold text-white transition-all duration-200 bg-[#0ABAB5] border border-transparent rounded sm:mt-10 font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            role="button"
                            rel="noreferrer"
                        >
                            Book my appointment
                        </a>
                        <div className="mt-8 flex">
                            <div className="pr-[20px] border-r-[2px] border-[#ADADAD]">
                                <h3 className="text-[25px] font-[700] font-[Roboto] text-[#1F3636]">10+ Years</h3>
                                <p className="text-[#1F3636] text-[15px] leading-3 font-[Roboto]">Experience</p>
                            </div>
                            <div className="pl-[20px]">
                                <h3 className="text-[25px] font-[700] font-[Roboto] text-[#1F3636]">
                                    {" "}
                                    &#62;6000 Patients
                                </h3>
                                <p className="text-[#1F3636] text-[15px] leading-3 font-[Roboto]">Treated Well</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header
