import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { Link } from "react-router-dom";

//images
import bg from "../../assets/images/bg.png";
import img3 from "../../assets/images/medications/painkillers.png";
import img4 from "../../assets/images/medications/pain-killers-regularly.png";
import img5 from "../../assets/images/medications/addicted-pain-killers.png";
import img6 from "../../assets/images/medications/drive-whilst-taking-painkillers.png";

const SimulatedImagePlaceholder = () => {
  return (
    <div
      style={{
        width: "100%", // Ensure it takes the full width of the parent container
        paddingTop: "56.25%", // This creates an aspect ratio of 16:9
        backgroundColor: "#e0e0e0", // Light gray color to simulate a placeholder
        borderRadius: "8px", // Optional: Adds rounded corners to the placeholder
      }}
    ></div>
  );
};

const PainMedications = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const Api = async () => {
      setLoading(true); // Start loading before API call
      const { data } = await axios.get("/pain-medication/get");
      setDatas(data.data);
      setLoading(false); // End loading after data is set
    };
    window.scrollTo(0, 0);
    Api();
  }, []);

  return (
    <div data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className="w-full flex flex-col items-center py-[120px] px-[30px]"
      >
        <h1 className="font-[Roboto] font-[700] text-white ms:!text-[48px] text-[30px]">
          Pain Medications
        </h1>
        <p className="text-white text-[18px] ms:text-[16px] text-center">
          Safe and effective use of appropriate medications to improve quality
          of life.
        </p>
      </div>

      <div className="py-[40px] md:py-[75px] px-5 max-w-7xl mx-auto">
        <h2 className="text-[#1F3636] text-2xl ms:text-[31px] text-center font-[600] mt-[40px]">
          Information about pain killers.
        </h2>

        <div className="sm:my-[50px] lg:!my-[60px] flex flex-wrap">
          <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
            <div className="w-[90%] sm:w-[70%] lg:w-[45%] mx-auto">
              <img
                className="object-cover w-full h-full max-w-full rounded-lg "
                src={img3}
                alt=""
              />
            </div>
            <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
              <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                What are the different types of painkillers?
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                There are two types – conventional painkillers such as
                paracetomol, anti-inflammatories and opioid-based medications
                and unconventional painkillers such as muscle relaxants,
                anti-depressants and anti-epileptic medications.
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                Several medications are available within each class of these
                medications.
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                Your pain condition guides which class of medication will be
                useful and you may have to try different medication to find the
                best one that suits you.{" "}
              </p>
            </div>
          </div>
          <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
            <div className="w-[90%] lg:hidden sm:w-[70%] lg:w-[45%] mx-auto">
              <img
                className="object-cover w-full h-full max-w-full rounded-lg "
                src={img4}
                alt=""
              />
            </div>
            <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
              <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                Do I need to take pain killers regularly?
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                It depends. It is advisable to take painkillers on ‘as required’
                basis. For certain pain conditions, particularly nerve pain
                conditions, they need to be taken regularly.
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                All medications have the potential to cause side-effects and the
                continued need should be reviewed on regular basis.
              </p>
            </div>
            <div className="w-[90%] hidden lg:block sm:w-[70%] lg:w-[45%] mx-auto">
              <img
                className="object-cover w-full h-full max-w-full rounded-lg "
                src={img4}
                alt=""
              />
            </div>
          </div>
          <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
            <div className="w-[90%] sm:w-[70%] lg:w-[45%] mx-auto">
              <img
                className="object-cover w-full h-full max-w-full rounded-lg "
                src={img5}
                alt=""
              />
            </div>
            <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
              <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                Will I get addicted to pain killers?
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                It is always important to be careful with medications. Most
                commonly, they cause withdrawal effects. Knowing how people get
                addicted and warning signs of addiction is the best defence
                against preventing addiction.
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                People develop tolerance to pain killers. This means the amount
                need for same effect increases over time. This should be
                addressed early. Certain types of medications such Opioids,
                Gabapentin and Pregabalin can cause addiction.
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                If you think there might a problem, please seek medical advice
                and help.
              </p>
            </div>
          </div>
          <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
            <div className="w-[90%] lg:hidden sm:w-[70%] lg:w-[45%] mx-auto">
              <img
                className="object-cover w-full h-full max-w-full rounded-lg "
                src={img6}
                alt=""
              />
            </div>
            <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
              <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                Can I drive whilst taking painkillers?
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                Yes, only if you are able to. It is your responsibility to
                decide whether driving ability is impaired or not at any given
                time. Some painkillers cause dizziness and sleepiness as
                side-effect and may impair your driving. Do not drive if you are
                sleepy, or have poor co-ordination, dizziness, impaired or slow
                thinking.
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                Please visit the{" "}
                <a
                  className="text-blue-400 underline "
                  href="https://www.gov.uk/drug-driving-law"
                >
                  https://www.gov.uk/drug-driving-law
                </a>{" "}
                to know more about individual medications and up-to-date
                information from government.
              </p>
            </div>
            <div className="w-[90%] hidden lg:block sm:w-[70%] lg:w-[45%] mx-auto">
              <img
                className="object-cover w-full h-full max-w-full rounded-lg "
                src={img6}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4 flex-wrap my-[50px]">
          {loading
            ? // Display loading cards
              Array.from({ length: 4 }).map((_, index) => (
                <div className="w-[95%] xs:w-[80%] sm:w-[45%] mx-auto flex flex-col overflow-hidden transition-all duration-200 transform bg-white border border-gray-100 shadow group rounded-xl animate-pulse">
                  <div className="flex shrink-0 aspect-w-4 aspect-h-3">
                    <SimulatedImagePlaceholder />
                  </div>
                  <div className="flex-1 px-4 py-5 space-y-3 sm:p-6">
                    <div className="h-4 bg-gray-300 rounded"></div>
                    <div className="w-3/4 h-4 bg-gray-300 rounded"></div>
                    <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
                  </div>
                </div>
              ))
            : datas.map((data) => (
                <div
                  key={data?._id}
                  className="w-[95%] xs:w-[80%] sm:w-[45%] mx-auto flex flex-col overflow-hidden transition-all duration-200 transform bg-white border border-gray-100 shadow group rounded-xl hover:shadow-lg hover:-translate-y-1"
                >
                  <Link
                    to={data._id}
                    title="Pain medication details"
                    className="flex shrink-0 aspect-w-4 aspect-h-3"
                  >
                    <img
                      className="object-cover w-full transition-all duration-200 transform h-72 group-hover:scale-110"
                      src={data.thumbnail}
                      alt=""
                    />
                  </Link>
                  <div className="flex-1 px-4 py-5 sm:p-6">
                    <Link to={data._id} title="" className="">
                      <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                        {data.title.length > 50
                          ? data.title.substring(0, 50) + "..."
                          : data.title}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            data.content.length > 250
                              ? data.content.substring(0, 250) + "..."
                              : data.content,
                        }}
                        className="mt-3 h-16 text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3"
                      ></div>
                    </Link>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default PainMedications;
