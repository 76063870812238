import { Turnstile } from "@marsidev/react-turnstile";
import React, { useState } from "react";
// import "./stepWindow.css";
const StepWindow = () => {
  const stepList = ["Step 1", "Step 2", "Step 3"];
  const [currentStep, setCurrentStep] = useState(0);
  const [token, setToken] = useState()
  const stepHandler = (type) => {
    if (type === "next") {
      setCurrentStep((prev) => prev + 1);
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  };

  // useEffect(() => {
  //   window.onloadTurnstileCallback = function () {
  //     Turnstile.render("#myWidget", {
  //       sitekey: "1x00000000000000000000AA",
  //       callback: function (token) {
  //         console.log(`Challenge Success ${token}`);
  //         /**
  //          * @description if token is not undefined that means it's a valid request
  //          */
  //         if (!token) {
  //           alert("Done");
  //         }
  //       },
  //     });
  //   };
  // }, []);
  return (
    <Turnstile
      onSuccess={(token) => setToken(token)}
      siteKey="1x00000000000000000000AA"
    />
    // <div className="container">
    //   <div>
    //     <div className="stepWindow">
    //       <h3 className="stepTitle">{stepList[currentStep]}</h3>
    //     </div>
    //     <div id="myWidget"></div>
    //     <button onClick={() => stepHandler("prev")}>Prev</button>
    //     <button onClick={() => stepHandler("next")}>next</button>
    //   </div>
    // </div>
  );





};

export default StepWindow;
