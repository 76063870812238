import React, { useRef, useState } from "react"
import { ChevronDown } from "react-feather"
import { Link, useLocation } from "react-router-dom"
import logo from "../../../assets/images/logo.png"

const Navbar = () => {
    const [status, setStatus] = useState(false)
    const [serviceStatus, setserviceStatus] = useState(false)
    const [navMenuStatus, setNavMenuStatus] = useState(false)
    const { pathname } = useLocation()

    const toggleMenu = (e) => {
        e.preventDefault()
        setNavMenuStatus(!navMenuStatus)
    }

    const nav = useRef()

    //   useEffect(() => {
    //     let isScrolling;
    //     window.addEventListener(
    //       "scroll",
    //       () => {
    //         nav.current.style.boxShadow = "2px 2px 10px #ddd";
    //         window.clearTimeout(isScrolling);
    //         isScrolling = setTimeout(() => {
    //           nav.current.style.boxShadow = "none";
    //         }, 500);
    //       },
    //       false
    //     );
    //   }, []);

    return (
        <div className="h-[90px]">
            <header
                ref={nav}
                className="mx-auto py-6 !z-30 fixed w-full bg-white top-0 left-0 drop-shadow-md "
            >
                <div className="container px-5 mx-auto max-w-7xl">
                    <div className="relative flex items-center justify-between">
                        <div className="flex-shrink-0">
                            <Link
                                to="/"
                                title=""
                                className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                            >
                                <img
                                    className="w-[110px] h-auto"
                                    src={logo}
                                    alt=""
                                />
                            </Link>
                        </div>

                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="text-gray-900"
                                onClick={() => setserviceStatus(false)}
                            >
                                <span
                                    x-show="!expanded"
                                    aria-hidden="true"
                                >
                                    <svg
                                        onClick={() => setStatus(!status)}
                                        className="w-7 h-7"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="hidden  !z-20 nav lg:flex md:items-center md:justify-center md:space-x-5 md:absolute md:inset-y-0 md:left-1/2 md:-translate-x-1/2  lg:space-x-10">
                            <Link
                                onClick={() => setserviceStatus(false)}
                                to="/"
                                title=""
                                className={`text-base font-medium  transition-all duration-200  font-pj hover:text-[#0ABAB5] ${
                                    pathname === "/" ? "text-[#0ABAB5]" : "text-gray-900"
                                } `}
                            >
                                Home
                            </Link>
                            <Link
                                onClick={() => setserviceStatus(false)}
                                to="/patients"
                                title=""
                                className={`text-base font-medium  transition-all duration-200  font-pj hover:text-[#0ABAB5] ${
                                    pathname === "/patients" ? "text-[#0ABAB5]" : "text-gray-900"
                                } `}
                            >
                                Patients
                            </Link>
                            <li className="relative list-none">
                                <a
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setserviceStatus(!serviceStatus)
                                    }}
                                    href=" "
                                    title=""
                                    className="flex items-center text-base font-medium text-gray-900 transition-all duration-200  font-pj hover:text-[#0ABAB5]  focus:text-[#0ABAB5]"
                                >
                                    Services
                                    <svg
                                        className="w-4 h-4 mx-1.5"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </a>
                                <div
                                    className={`${
                                        serviceStatus ? "" : "hidden !z-10"
                                    } absolute rounded-lg top-[110%] left-[-5%] `}
                                >
                                    <ul className="w-[220px] rounded-lg bg-gray-100 mt-2 relative !z-10 shadow-md">
                                        <li>
                                            <Link
                                                onClick={() => setserviceStatus(false)}
                                                className={`w-full py-3 pl-5 block rounded-t-lg font-semibold hover:bg-[#0ABAB5] hover:text-white transition duration-300 border-b-2 border-white ${
                                                    pathname === "/consultation"
                                                        ? "bg-[#0ABAB5] text-white"
                                                        : "bg-transparent"
                                                }`}
                                                to="/consultation"
                                            >
                                                Consultation
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => setserviceStatus(false)}
                                                className={`w-full py-3 pl-5 block  font-semibold hover:bg-[#0ABAB5] hover:text-white transition duration-300 border-b-2 border-white ${
                                                    pathname === "/investigations"
                                                        ? "bg-[#0ABAB5] text-white"
                                                        : "bg-transparent"
                                                }`}
                                                to="/investigations"
                                            >
                                                Investigations/Referrals
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => setserviceStatus(false)}
                                                className={`w-full py-3 pl-5 block  font-semibold hover:bg-[#0ABAB5] hover:text-white transition duration-300 border-b-2 border-white ${
                                                    pathname === "/self-management"
                                                        ? "bg-[#0ABAB5] text-white"
                                                        : "bg-transparent"
                                                }`}
                                                to="/self-management"
                                            >
                                                Self Management
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => setserviceStatus(false)}
                                                className={`w-full py-3 pl-5 block  font-semibold hover:bg-[#0ABAB5] hover:text-white transition duration-300 border-b-2 border-white ${
                                                    pathname === "/pain-medication"
                                                        ? "bg-[#0ABAB5] text-white"
                                                        : "bg-transparent"
                                                }`}
                                                to="/pain-medication"
                                            >
                                                Pain Medications
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => setserviceStatus(false)}
                                                className={`w-full py-3 pl-5 block  font-semibold hover:bg-[#0ABAB5] hover:text-white transition duration-300 border-b-2 border-white ${
                                                    pathname === "/pain-injection"
                                                        ? "bg-[#0ABAB5] text-white"
                                                        : "bg-transparent"
                                                }`}
                                                to="/pain-injection"
                                            >
                                                Pain Injections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => setserviceStatus(false)}
                                                className={`w-full py-3 pl-5 block font-semibold hover:bg-[#0ABAB5] hover:text-white transition duration-300 rounded-b-lg ${
                                                    pathname === "/pain-condition"
                                                        ? "bg-[#0ABAB5] text-white"
                                                        : "bg-transparent"
                                                }`}
                                                to="/pain-condition"
                                            >
                                                Pain Conditions
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <Link
                                onClick={() => setserviceStatus(false)}
                                to="/about"
                                title=""
                                className={`text-base font-medium  transition-all duration-200  font-pj hover:text-[#0ABAB5] ${
                                    pathname === "/about" ? "text-[#0ABAB5]" : "text-gray-900"
                                } `}
                            >
                                About Me
                            </Link>
                            <Link
                                onClick={() => setserviceStatus(false)}
                                to="/contact"
                                title=""
                                className={`text-base font-medium  transition-all duration-200  font-pj hover:text-[#0ABAB5] ${
                                    pathname === "/contact" ? "text-[#0ABAB5]" : "text-gray-900"
                                } `}
                            >
                                Contact Me
                            </Link>
                        </div>

                        <div
                            className="hidden lg:flex"
                            onClick={() => setserviceStatus(false)}
                        >
                            <a
                                href="tel:+08081895654"
                                title=""
                                className="inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 text-white transition-all duration-200 bg-[#0ABAB5] border border-transparent rounded hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                role="button"
                            >
                                Call Now
                            </a>
                        </div>
                    </div>

                    <nav
                        className={`${
                            status ? "block" : "hidden"
                        } !z-20 fixed w-full h-[100vh] bg-[#050000ea] left-0 top-0`}
                        x-collapse
                    >
                        <span className="absolute top-5 right-6">
                            <svg
                                onClick={() => setStatus(!status)}
                                className="text-white cursor-pointer w-7 h-7"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </span>
                        <div className="px-1 py-8 w-[90%] ms:w-[60%] mx-auto mt-[60px]">
                            <div className="grid gap-y-7">
                                <Link
                                    onClick={() => setStatus(false)}
                                    to="/"
                                    title=""
                                    className={`flex p-3 -m-3 text-base font-medium text-white transition-all duration-200 rounded hover:bg-[#0ABAB5] text-center focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 ${
                                        pathname === "/" ? "bg-[#0ABAB5]" : ""
                                    }`}
                                >
                                    Home
                                </Link>
                                <Link
                                    onClick={() => setStatus(false)}
                                    to="/patients"
                                    title=""
                                    className={`flex p-3 -m-3 text-base font-medium text-white transition-all duration-200 rounded hover:bg-[#0ABAB5] text-center focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 ${
                                        pathname === "/patients" ? "bg-[#0ABAB5]" : ""
                                    }`}
                                >
                                    Patients
                                </Link>
                                <li className="w-full list-none">
                                    <Link
                                        to=""
                                        onClick={toggleMenu}
                                        title=""
                                        className="flex justify-between p-3 -m-3 text-base font-medium text-white transition-all duration-200 rounded hover:bg-[#0ABAB5] text-center focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                                    >
                                        Services
                                        <ChevronDown
                                            size={25}
                                            className={`text-white transition duration-300 ${
                                                navMenuStatus ? "rotate-180" : "rotate-0"
                                            }`}
                                        />
                                    </Link>
                                    <ul className={`${navMenuStatus ? "block" : "hidden"} ml-5 mt-4`}>
                                        <li>
                                            <Link
                                                onClick={() => setStatus(false)}
                                                className={`pl-4 rounded w-full py-3 block font-semibold hover:bg-[#0ABAB5] text-white transition duration-300 ${
                                                    pathname === "/consultation" ? "bg-[#0ABAB5]" : ""
                                                }`}
                                                to="/consultation"
                                            >
                                                Consultation
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => setStatus(false)}
                                                className={`pl-4 rounded w-full py-3 block font-semibold hover:bg-[#0ABAB5] text-white transition duration-300 ${
                                                    pathname === "/investigations" ? "bg-[#0ABAB5]" : ""
                                                }`}
                                                to="/investigations"
                                            >
                                                Investigations/Referrals
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => setStatus(false)}
                                                className={`pl-4 rounded w-full py-3 block font-semibold hover:bg-[#0ABAB5] text-white transition duration-300 ${
                                                    pathname === "/self-management" ? "bg-[#0ABAB5]" : ""
                                                }`}
                                                to="/self-management"
                                            >
                                                Self Management
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => setStatus(false)}
                                                className={`pl-4 rounded w-full py-3 block font-semibold hover:bg-[#0ABAB5] text-white transition duration-300 ${
                                                    pathname === "/pain-medication" ? "bg-[#0ABAB5]" : ""
                                                }`}
                                                to="/pain-medication"
                                            >
                                                Pain Medications
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => setStatus(false)}
                                                className={`pl-4 rounded w-full py-3 block font-semibold hover:bg-[#0ABAB5] text-white transition duration-300 ${
                                                    pathname === "/pain-injection" ? "bg-[#0ABAB5]" : ""
                                                }`}
                                                to="/pain-injection"
                                            >
                                                Pain Injections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => setStatus(false)}
                                                className={`pl-4 rounded w-full py-3 block font-semibold hover:bg-[#0ABAB5] text-white transition duration-300 ${
                                                    pathname === "/pain-condition" ? "bg-[#0ABAB5]" : ""
                                                }`}
                                                to="/pain-condition"
                                            >
                                                Pain Conditions
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <Link
                                    onClick={() => setStatus(false)}
                                    to="/about"
                                    title=""
                                    className={`flex p-3 -m-3 text-base font-medium text-white transition-all duration-200 rounded hover:bg-[#0ABAB5] text-center focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 ${
                                        pathname === "/about" ? "bg-[#0ABAB5]" : ""
                                    }`}
                                >
                                    About Me
                                </Link>
                                <Link
                                    onClick={() => setStatus(false)}
                                    to="/contact"
                                    title=""
                                    className={`flex p-3 -m-3 text-base font-medium text-white transition-all duration-200 rounded hover:bg-[#0ABAB5] text-center focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 ${
                                        pathname === "/contact" ? "bg-[#0ABAB5]" : ""
                                    }`}
                                >
                                    Contact Me
                                </Link>

                                <a
                                    href="tel:+12468880653"
                                    title=""
                                    className="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white transition-all duration-200 bg-[#0ABAB5] border border-transparent rounded hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                    role="button"
                                >
                                    Call Now
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Navbar
