import React from "react"

//images
import img1 from "../../../assets/images/1.png"
import img3 from "../../../assets/images/3.png"
import img4 from "../../../assets/images/4.png"
import dummyUser from "../../../assets/images/dummyUser.png"

//carousel
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const Comment = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1250 },
            items: 3
        },
        minTablet: {
            breakpoint: { max: 950, min: 600 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 1
        }
    }

    //comment data
    const data = [
        {
            img: dummyUser,
            des: "I'm delighted with the results of the procedure, thank you so much, It's made a huge difference to my quality of life and  my mental state .",
            name: "Anonymous",
            componayStatus: "CEO, M......,Ltd.",
            star: 5
        },
        {
            img: dummyUser,
            des: "I am writing to extol the virtues of the neurostimulator implanted in my back 18 months ago. And to express my gratitude to all the staff involved in this process – nursing, doctors, and physios. When I first read the leaflets with people saying ‘it changed their lives’ I thought it was a bit far-fetched but I would now gladly echo that sentiment.",
            name: "Anonymous",
            componayStatus: "CEO, P....... Ltd.",
            star: 5
        },
        {
            img: dummyUser,
            des: "I would just like to take this opportunity to thank you for all you have done for me. You have given me my life back and for that I am truly thankful.",
            name: "Mike Rose",
            componayStatus: "CEO, Parkview Int. Ltd.",
            star: 4
        },
        {
            img: dummyUser,
            des: "It was a real pleasure to meet you and learn what you could do to help me with the pain I had in my back. You are a real expert in your field of work.",
            name: "Mike Rose",
            componayStatus: "CEO, Parkview Int. Ltd.",
            star: 4
        },
        {
            img: dummyUser,
            des: "Thank you for your kind attention. Sadly, the steroid injections did not work but I was very grateful fo your kind attention. You are very compassionate.",
            name: "David Johns",
            componayStatus: "CEO, Parkview Int. Ltd.",
            star: 3
        }
    ]

    return (
        <div className="py-[40px] md:py-[70px]">
            <h1 className="text-[31px] font-[600] text-center font-[Roboto]">Patients who loved my treatment!</h1>

            <Carousel
                showDots={true}
                removeArrowOnDeviceType={["tablet", "mobile", "minTablet", "desktop"]}
                responsive={responsive}
                className="mt-[50px] pb-[40px] sm:w-[90%] lg:w-[80%] mx-auto bg-white"
            >
                {data.map((data, i) => {
                    return (
                        <div
                            key={i}
                            // style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)" }}
                            className="mx-[10px] p-[25px]"
                            style={{
                                boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)",
                            }}
                        >
                            <p className="mt-[20px] text-[16px] text-[#333333]">{data.des}</p>

                            <div className="mt-[20px]">
                                {/* <div>
                                    <h6 className="text-[16px] font-[600] font-[Roboto]">{data.name}</h6>
                                    <p className="text-[13px] text-[#333333] font-[Roboto]">{data.componayStatus}</p>
                                </div> */}
                                <div className=" w-8 h-8 overflow-hidden rounded-full relative">
                                    <img
                                        src={data?.img}
                                        alt=""
                                        className=" w-full h-full absolute inset-0 object-cover"
                                    />
                                </div>
                                <div
                                    id="ratingStar"
                                    className=""
                                >
                                    {data.star === 1 && (
                                        <div>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#D7DAE1]"></i>
                                            <i className="fa-solid fa-star text-[#D7DAE1]"></i>
                                            <i className="fa-solid fa-star text-[#D7DAE1]"></i>
                                            <i className="fa-solid fa-star text-[#D7DAE1]"></i>
                                        </div>
                                    )}
                                    {data.star === 2 && (
                                        <>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#D7DAE1]"></i>
                                            <i className="fa-solid fa-star text-[#D7DAE1]"></i>
                                            <i className="fa-solid fa-star text-[#D7DAE1]"></i>
                                        </>
                                    )}
                                    {data.star === 3 && (
                                        <>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#D7DAE1]"></i>
                                            <i className="fa-solid fa-star text-[#D7DAE1]"></i>
                                        </>
                                    )}
                                    {data.star === 4 && (
                                        <>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#D7DAE1]"></i>
                                        </>
                                    )}
                                    {data.star === 5 && (
                                        <>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                            <i className="fa-solid fa-star text-[#FAAF38]"></i>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Carousel>
            <div className="text-center">
                <a
                    href="https://www.phin.org.uk/profiles/consultants/dr-saravanakumar-kanakarajan-183780"
                    title=""
                    target="_blank"
                    className="inline-flex px-8 py-3 mt-8 text-lg font-bold text-white transition-all duration-200 bg-[#0ABAB5] border border-transparent rounded sm:mt-10 font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    role="button"
                    rel="noreferrer"
                >
                    View More Patient Review
                </a>
            </div>
        </div>
    )
}

export default Comment
