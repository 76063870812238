import React, { useEffect, useState } from "react";
import axios from "../../axios";

//images
import { Link } from "react-router-dom";
import bg from "../../assets/images/bg.png";
import img1 from "../../assets/images/Self-management/selfManagement.png";

const SimulatedImagePlaceholder = () => {
  return (
    <div
      style={{
        width: "100%", // Ensure it takes the full width of the parent container
        paddingTop: "56.25%", // This creates an aspect ratio of 16:9
        backgroundColor: "#e0e0e0", // Light gray color to simulate a placeholder
        borderRadius: "8px", // Optional: Adds rounded corners to the placeholder
      }}
    ></div>
  );
};

const SelfManagement = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const Api = async () => {
      setLoading(true); // Start loading before API call
      const { data } = await axios.get("/self-management/get");

      setDatas(data.data);
      setLoading(false); // End loading after data is set
    };
    window.scrollTo(0, 0);
    Api();
  }, []);

  return (
    <div data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className="w-full flex flex-col items-center py-[120px] px-[30px]"
      >
        <h1 className="font-[Roboto] font-[700] text-white ms:!text-[48px] text-[30px]">
          Self-management
        </h1>
        <p className="text-white text-[18px] ms:text-[16px] text-center">
          A core component in the successful chronic pain management
        </p>
      </div>
      <div className="py-[40px] md:py-[75px] px-5 max-w-7xl mx-auto">
        <h2 className="text-[#1F3636] text-2xl ms:text-[31px] text-center font-[600] mt-[40px]">
          Self-management
        </h2>
        <div>
          <img
            className=" md:w-[80%] rounded-md lg:rounded-xl md:h-[500px] object-cover block mx-auto mt-10"
            src={img1}
            alt=""
          />
        </div>
        <div>
          <p className="text-[#7F7F9B] mt-6 text-[16px] text-center px-5">
            My aim is to help you manage the pain and its effects on your life.
            It <br className="hidden sm:block" /> is essential to understand
            chronic pain and its effects, so that you{" "}
            <br className="hidden sm:block" /> can recognise them and manage
            successfully.
          </p>
        </div>

        <div className="md:py-[80px] flex flex-wrap">
          <div className="w-[95%] xs:w-[80%] sm:w-[45%] mx-auto">
            <h3 className="text-[#252525] font-[600] font-[Roboto] text-[20px]">
              What is pain?
            </h3>
            <iframe
              // height="315"
              src="https://www.youtube.com/embed/jIwn9rC3rOI?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="w-full mt-4 rounded-lg h-72"
            ></iframe>
            {/* <img src={img5} className="w-full mt-4" alt="" /> */}
          </div>
          <div className="w-[95%] xs:w-[80%] sm:w-[45%] mx-auto">
            <h3 className="text-[#252525] font-[600] font-[Roboto] text-[20px]">
              What is self-management?
            </h3>
            <iframe
              // height="315"
              src="https://www.youtube.com/embed/ac13iuGByRQ?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="w-full mt-4 rounded-lg h-72"
            ></iframe>
          </div>
        </div>

        <div className="flex flex-wrap gap-8">
          {loading
            ? // Display loading cards
              Array.from({ length: 4 }).map((_, index) => (
                <div className="w-[95%] xs:w-[80%] sm:w-[45%] mx-auto flex flex-col overflow-hidden transition-all duration-200 transform bg-white border border-gray-100 shadow group rounded-xl animate-pulse">
                  <div className="flex shrink-0 aspect-w-4 aspect-h-3">
                    <SimulatedImagePlaceholder />
                  </div>
                  <div className="flex-1 px-4 py-5 space-y-3 sm:p-6">
                    <div className="h-4 bg-gray-300 rounded"></div>
                    <div className="w-3/4 h-4 bg-gray-300 rounded"></div>
                    <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
                  </div>
                </div>
              ))
            : datas.map((data) => (
                <div
                  key={data._id}
                  className="w-[95%] xs:w-[80%] sm:w-[45%] mx-auto flex flex-col overflow-hidden transition-all duration-200 transform bg-white border border-gray-100 shadow group rounded-xl hover:shadow-lg hover:-translate-y-1"
                >
                  <Link
                    to={data._id}
                    target="_blank"
                    title="Click to See Details"
                    className="flex shrink-0 aspect-w-4 aspect-h-3"
                    rel="noreferrer"
                  >
                    <img
                      className="object-cover w-full transition-all duration-200 transform h-72 group-hover:scale-110"
                      src={data.thumbnail}
                      alt=""
                    />
                  </Link>
                  <div className="flex-1 px-4 py-5 sm:p-6">
                    <Link
                      to={data._id}
                      target="_blank"
                      title="Click to See Details"
                      className=""
                      rel="noreferrer"
                    >
                      <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                        {data.title.length > 50
                          ? data.title.substring(0, 50) + "..."
                          : data.title}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            data.content.length > 250
                              ? data.content.substring(0, 250) + "..."
                              : data.content,
                        }}
                        className="mt-3 h-16 text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3"
                      ></div>
                    </Link>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default SelfManagement;
