import React from "react";
import { Outlet } from "react-router-dom";

const BlankLayout = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Outlet />
      {/* <Footer /> */}
    </div>
  );
};

export default BlankLayout;
