import React, { useEffect } from "react";
import Comment from "../../@core/components/Comments";
import Header from "../../@core/components/Header";
import Question from "../../@core/components/Question";
import Services from "../../@core/components/Services";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div
        className="max-w-7xl mx-auto px-5"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <Header />
        <Services />
        <Comment />
        <Question />
      </div>
    </>
  );
};

export default Home;
