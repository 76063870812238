import React, { useEffect, useState } from "react";
import SingalService from "../../@core/components/SingalService";
import axios from "../../axios";

//images
import bg from "../../assets/images/bg.png";
import img1 from "../../assets/images/Pain-Injections/1.png";
import img2 from "../../assets/images/Pain-Injections/2.png";

const PainInjections = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const Api = async () => {
      setLoading(true); // Start loading
      const { data } = await axios.get("/pain-injection/get")
      setDatas(data.data);
      setLoading(false); // End loading
    };
    Api();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className="w-full flex flex-col items-center py-[120px] px-[30px]"
      >
        <h1 className="font-[Roboto] font-[700] text-white ms:!text-[48px] text-[30px]">
          Pain Injections
        </h1>
        <p className="text-white text-[18px] ms:text-[16px] text-center">
          Injections are recognised tool in management of chronic pain
        </p>
      </div>

      <div className="py-[40px] md:py-[75px] px-5 max-w-7xl mx-auto">
        <h2 className="text-[#1F3636] text-2xl ms:text-[31px] text-center font-[600] mt-[40px]">
          Questions and answers about pain injections{" "}
        </h2>

        <div className="sm:my-[50px] lg:!my-[60px] flex flex-wrap">
          <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
            <div className="w-[90%] sm:w-[70%] lg:w-[45%] mx-auto">
              <img className="w-full h-full" src={img1} alt="" />
            </div>
            <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
              <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                How pain injections help you?
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                Injections can help your pain by reducing some of the pain
                signals and, though pain relief may be short-lived, some people
                can get significant and long lasting pain relief from these
                injections.{" "}
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                It may not be a cure. Sometimes injections are used to help to
                identify the area from where your pain is arising thus helping
                with diagnosis.
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                Injection usually contain local anaesthetic often with a small
                amount of steroid. The injection is usually undertaken alongside
                other treatments such as physiotherapy.
              </p>
            </div>
          </div>
          <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
            <div className="w-[90%] lg:hidden sm:w-[70%] lg:w-[45%] mx-auto">
              <img
                className="object-cover w-full h-full max-w-full rounded-lg "
                src={img2}
                alt=""
              />
            </div>
            <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
              <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                Is it a steroid injection?
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                No. not all injections are steroid injections.{" "}
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                Steroids (corticosteroids) are commonly used in pain injections
                but are licensed for specific routes of adminstration only.{" "}
              </p>
              <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                Some routes of adminstration to treat pain are not on license.
                This is referred to as off-label use.
              </p>
            </div>
            <div className="w-[90%] hidden overflow-hidden h-[300px] rounded-lg lg:block sm:w-[70%] lg:w-[45%] mx-auto">
              <img
                className="object-cover w-full h-full max-w-full rounded-lg "
                src={img2}
                alt=""
              />
            </div>
          </div>
        </div>

        <h2 className="text-[#1F3636] text-2xl ms:text-[31px] text-center font-[600] mt-[40px]">
          More information about individual injections can be found below
        </h2>
        <div className="mt-[40px] flex flex-wrap">
          {loading
            ? // Display loading cards
              Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={index}
                  className="mt-10 w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[30px] px-[25px] mx-auto animate-pulse"
                  style={{
                    boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)",
                    border: "1px solid #F3F3F3",
                    backgroundColor: "#f3f3f3",
                  }}
                >
                  <div className="bg-[#0ABAB5] w-[40px] h-[40px] rounded-full"></div>
                  <div className="h-16 mt-3 bg-[#ddd] w-3/4"></div>
                  <div className="mt-3 h-5 bg-[#ddd] w-1/2"></div>
                </div>
              ))
            : datas?.map((data, index) => (
                <SingalService
                  key={data._id}
                  number={index + 1}
                  title={data.title}
                  link={`/pain-injection/${data._id}`}
                  date={data.createdAt}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default PainInjections;
