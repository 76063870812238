// PainConditions.js modifications
import React, { useEffect, useState } from "react";
import SingalService from "../../@core/components/SingalService";
import axios from "../../axios";
import bg from "../../assets/images/bg.png";

const PainConditions = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const Api = async () => {
      setLoading(true); // Start loading
      const { data } = await axios.get("/pain-condition/get");
      setDatas(data.data);
      setLoading(false); // End loading
    };
    Api();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className="w-full flex flex-col items-center py-[120px] px-[30px]"
      >
        <h1 className="font-[Roboto] font-[700] text-white ms:!text-[48px] text-[30px]">
          Pain Conditions
        </h1>
      </div>

      <div className="py-[40px] md:py-[75px] px-5 max-w-7xl mx-auto">
        <h2 className="text-[#1F3636] text-2xl ms:text-[31px] text-center font-[600] mt-[40px]">
          Information about Pain conditions can be found below
        </h2>
        <div className="mt-[40px] flex flex-wrap">
          {loading
            ? // Display loading cards
              Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={index}
                  className="mt-10 w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[30px] px-[25px] mx-auto animate-pulse"
                  style={{
                    boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)",
                    border: "1px solid #F3F3F3",
                    backgroundColor: "#f3f3f3",
                  }}
                >
                  <div className="bg-[#0ABAB5] w-[40px] h-[40px] rounded-full"></div>
                  <div className="h-16 mt-3 bg-[#ddd] w-3/4"></div>
                  <div className="mt-3 h-5 bg-[#ddd] w-1/2"></div>
                </div>
              ))
            : datas.map((data, index) => (
                <SingalService
                  key={data._id}
                  number={index + 1}
                  title={data.title}
                  link={`/pain-condition/${data._id}`}
                  date={data.createdAt}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default PainConditions;
