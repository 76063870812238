import React, { useEffect } from "react"

//images
import img1 from "../../assets/images/Consultation/1.png"
import img2 from "../../assets/images/Consultation/2.png"
import img3 from "../../assets/images/Consultation/3.svg"
import infographic from "../../assets/images/Consultation/infographic.jpg"
import img5 from "../../assets/images/Consultation/5.svg"
import img6 from "../../assets/images/Consultation/6.svg"
import img7 from "../../assets/images/Consultation/7.png"
import img8 from "../../assets/images/Consultation/8.png"
import img9 from "../../assets/images/Consultation/9.png"
import bg from "../../assets/images/Consultation/bg.png"

const Consultation = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div
            className=""
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
        >
            <div
                style={{ backgroundImage: `url(${bg})` }}
                className="w-full flex flex-col items-center py-[120px] px-[30px]"
            >
                <h1 className="font-[Roboto] font-[700] text-white ms:!text-[48px] text-[30px]">Consultation</h1>
                <p className="text-white text-[18px] ms:text-[16px] text-center">
                    An essential component for effective clinical care.
                </p>
            </div>
            <div className="py-[40px] md:py-[75px] px-5 max-w-7xl mx-auto">
                <h2 className="text-[#1F3636] text-2xl ms:text-[31px] text-center font-[600] mt-[40px]">
                    Questions and answers about consultation
                </h2>
                <div className="flex flex-wrap my-[50px] md:h-[450px]">
                    <div className="w-[95%] xs:w-[80%] sm:w-[45%] md:h-full mx-auto flex flex-col overflow-hidden transition-all duration-200 transform bg-white border border-gray-100 shadow group rounded-xl hover:shadow-lg hover:-translate-y-1">
                        <a
                            href="#"
                            title=""
                            className="flex md:h-[50%]"
                        >
                            <img
                                className="object-cover w-full h-full transition-all duration-200 transform group-hover:scale-110"
                                src={img1}
                                alt=""
                            />
                        </a>
                        <div className="flex-1 px-4 py-5 sm:p-6">
                            <a
                                href="#"
                                title=""
                                className=""
                            >
                                <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                                    When are my clinics held?
                                </p>
                                <p className="mt-3 text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                    Usually on Tuesday mornings, from 9 to 12. I often do extra clinics on other days if
                                    required.
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className="w-[95%] mt-5 sm:mt-0 xs:w-[80%] sm:w-[45%] mx-auto flex flex-col overflow-hidden transition-all duration-200 transform bg-white border border-gray-100 shadow group rounded-xl hover:shadow-lg hover:-translate-y-1">
                        <a
                            href="#"
                            title=""
                            className="flex h-[50%]"
                        >
                            <img
                                className="object-cover w-full h-full transition-all duration-200 transform group-hover:scale-110"
                                src={img2}
                                alt=""
                            />
                        </a>
                        <div className="flex-1 px-4 py-5 sm:p-6">
                            <a
                                href="#"
                                title=""
                                className=""
                            >
                                <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                                    How long are the appointments?
                                </p>
                                <p className="mt-3 text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                    A “new” and “return visit” clinic appointment may last 40 and 20 minutes
                                    respectively.
                                </p>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="sm:my-[50px] lg:!my-[150px] flex flex-wrap">
                    <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
                        <div className="w-[90%] sm:w-[70%] lg:w-[45%] mx-auto my-auto">
                            <img
                                className="object-cover w-full h-full max-w-full rounded-lg "
                                src={img3}
                                alt=""
                            />
                        </div>
                        <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
                            <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                                What happens during a consultation?
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                We will discuss about your pain condition and its impact. I will examine you, review the
                                available tests such as MRI scans and formulate a management plan that suits your needs.
                                Occasionally, you might need additional blood tests or x-rays; these will be carried out
                                same day. Any further scans such as Ultrasound, CT or MRI scans will be arranged later.
                                Please note pain injections are not carried out on the same day of the consultation. If
                                you are looking to get the injection on the same day, please contact my secretary in
                                advance
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                MRI scans are done within 1 to 2 weeks of your appointment with me and I prefer if they
                                are reported by one of specialist Musculoskeletal Radiologists (rather than a general
                                Radiologist). The report therefore may take a week or so, but I think this provides a
                                higher quality report to you.{" "}
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                Occasionally I may need to send to University of Aberdeen for a special MRI scan. Based
                                on the information gathered, we will make a pain management plan.
                            </p>
                        </div>
                    </div>
                    <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
                        {/* <div className="w-[90%] h-[150px] rounded-md overflow-hidden lg:hidden sm:w-[70%] lg:w-[45%] mx-auto"> */}
                        <div className="w-[90%] sm:w-[70%] lg:w-[45%] mx-auto my-auto overflow-hidden lg:hidden">
                            <img
                                className="object-cover w-full h-full max-w-full rounded-lg "
                                src={infographic}
                                alt=""
                            />
                        </div>
                        <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
                            <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                                Do I need a referral letter?
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                Referral letters provide information about the diagnosis, and management you have
                                received so far. This is important so that there is no duplication of tests, and onward
                                referrals.{" "}
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                You can bring the information about various pain management options you have tried so
                                far as well. This will help me in diagnosing, treating and helping you to manage your
                                chronic pain condition.
                            </p>
                        </div>
                        <div className="w-[90%] hidden lg:block sm:w-[70%] h-[300px] rounded-lg overflow-hidden lg:w-[45%] mx-auto">
                            <img
                                className="object-cover w-full h-full max-w-full rounded-lg "
                                src={infographic}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
                        <div className="w-[90%] sm:w-[70%] lg:w-[45%] mx-auto">
                            <img
                                className="object-cover w-full h-full max-w-full rounded-lg "
                                src={img5}
                                alt=""
                            />
                        </div>
                        <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
                            <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                                Do I get a copy of clinic letters?
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                Yes. I use an online portal called carebit. You will be invited to register in the
                                portal. You can access your clinic letters in this portal. Check Here
                                (https://www.carebit.co/features/patient-portal)
                            </p>
                        </div>
                    </div>
                    <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
                        <div className="w-[90%] lg:hidden sm:w-[70%] lg:w-[45%] mx-auto">
                            <img
                                className="object-cover w-full h-full max-w-full rounded-lg "
                                src={img6}
                                alt=""
                            />
                        </div>
                        <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
                            <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                                Will my health insurer pay?
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                I am delighted to see both self-funding and insured patients. I am recognised by medical
                                insurance companies such as AXA, AVIVA, BUPA, Cigna, Vitality, WPA and others. Your
                                insurer should be aware of your referral, and you should get authorisation for
                                consultation.{" "}
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                Depending on your policy and the provider, there may be excess on your policy, and
                                further authorisation needed for any subsequent investigations and treatment.{" "}
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                The cost of consultation are the same for everyone who attend the clinic; but different
                                insurers have different threshold for consultation payments. So there may be a shortfall
                                to cover my standard consultation fees.
                            </p>
                        </div>
                        <div className="w-[90%] hidden lg:block sm:w-[70%] lg:w-[45%] mx-auto">
                            <img
                                className="object-cover max-w-full rounded-lg"
                                src={img6}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
                        <div className="w-[90%] sm:w-[70%] lg:w-[45%] mx-auto">
                            <img
                                className="object-cover w-full h-full max-w-full rounded-lg "
                                src={img7}
                                alt=""
                            />
                        </div>
                        <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
                            <p className="text-lg font-[600] text-[#252525] font-[Roboto]">Information about costs</p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                The standard fees for a new consultation is £250/ (correct as of April 2023) and the
                                review appointment is £144/=. There are occasions where telephone consultations are
                                appropriate. The cost of telephone consultations is £108 up to 20 minutes and £200 up to
                                40 minutes I do the fee-invoicing directly with the insurer using carebit portal. You
                                might receive communications.
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                If you are a self-pay patient, you will be invoiced. Normally, the invoices are sent
                                electronically via carebit portal. There are various payment options including bank
                                transfer, and card payments. If the insurer fails to acknowledge or pay, we will invoice
                                you directly.
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                For treatments, particularly pain injections, insurance companies must always be
                                informed by you. For authorisation, they normally ask for a code, called OPCS code. I
                                will provide these codes for you.
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                You can find them in the information leaflets as well. The pre-authorisation department
                                at Albyn hospital can help you as well. The booking office at Albyn hospital will
                                provide you the dates for the procedure. If you are self-pay, please contact Albyn
                                hospital for the cost of your procedure – phone 0808 189 5654 or email
                                pricing.albyn@circlehealthgroup.co.uk
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consultation
