import React, { useEffect } from "react"

//images
import bg from "../../assets/images/bg.png"
import img1 from "../../assets/images/Consultation/3.svg"
import img2 from "../../assets/images/Consultation/4.svg"

const Investigations = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <div
                style={{ backgroundImage: `url(${bg})` }}
                className="w-full flex flex-col items-center py-[120px] px-[30px]"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1000"
            >
                <h1 className="font-[Roboto] font-[700] text-white ms:!text-[48px] text-[30px]">
                    Investigations/Referrals{" "}
                </h1>
                <p className="text-white text-[18px] ms:text-[16px] text-center">
                    Pain Management needs multidisciplinary approach
                </p>
            </div>
            <div className="py-[40px] md:py-[75px] px-5 max-w-7xl mx-auto">
                <h2 className="text-[#1F3636] text-2xl ms:text-[31px] text-center font-[600] mt-[40px]">
                    Questions and answers about investigations/referrals
                </h2>
                <div className="sm:my-[50px] flex flex-wrap">
                    <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
                        <div className="w-[90%] sm:w-[70%] lg:w-[45%] mx-auto rounded-lg overflow-hidden">
                            <img
                                className=" w-full h-full object-cover rounded-lg max-w-full"
                                src={img1}
                                alt=""
                            />
                        </div>
                        <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
                            <p className="text-lg font-[600] text-[#252525] font-[Roboto]">
                                About my pain management clinic
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                I undertake comprehensive assessment of your pain condition and its impact on your life.
                                In general, other specialists or general practitioners would have investigated your
                                condition. Often there is no need to investigate further. It is important to review your
                                tests, particularly scans.
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                Though the scan reports are useful, more information can be gathered by reviewing your
                                scan pictures. If this has been undertaken in another hospital, please inform us. We
                                will make our best efforts to transfer the image to Albyn hospital, after the
                                consultation. Please bring a copy of blood test that may have been carried out in the
                                last 6 months. If there is need for any further tests, these will be organised
                                accordingly.
                            </p>
                        </div>
                    </div>
                    <div className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto">
                        <div className="w-[90%] lg:hidden sm:w-[70%] lg:w-[45%] mx-auto">
                            <img
                                className=" w-full h-full object-cover rounded-lg max-w-full"
                                src={img2}
                                alt=""
                            />
                        </div>
                        <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
                            <p className="text-lg font-[600] text-[#252525] font-[Roboto]">Multidisciplinary approach</p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                Often, the management plan would involve multidisciplinary approach. So it is common to
                                refer to other relevant allied healthcare professionals and carers such as
                                physiotherapy, psychology, nurses and pharmacists.
                            </p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                I will also do onward referral to other healthcare professionals if appropriate.
                            </p>
                        </div>
                        <div className="w-[90%] hidden lg:block sm:w-[70%] lg:w-[45%] mx-auto">
                            <img
                                className=" w-full h-full object-cover rounded-lg max-w-full"
                                src={img2}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Investigations
