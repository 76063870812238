import React from "react";

const Advice = () => {
  return (
    <section className="relative py-12 overflow-hidden sm:py-16 lg:py-20 bg-[#DEF6F5]">
      <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="flex flex-col items-center text-center md:text-left md:items-start sm:max-w-md lg:max-w-2xl">
            <h2 className="mt-4 b text-3xl font-semibold tracking-tight text-[#1F3636] sm:text-4xl lg:text-[39px] md:ml-4 lg:ml-8 md:mt-0">
              <span className=" font-semibold" >
              Don’t delay care for 
              </span>
              <br /> those you love!
            </h2>
            <p className="mt-2 text-lg text-gray-600 font-inter md:ml-4 lg:ml-8">
              Get a treatment that you can afford!
            </p>
          </div>

          <div className="flex flex-col items-center mt-8 space-y-6 md:mt-0">
            <a
              href="https://www.circlehealthgroup.co.uk/online-booking?consultant=C5203328"
              title=""
              target="_blank"
              className="inline-flex px-8 py-3 mt-8 text-base sm:text-lg font-bold text-white transition-all duration-200 bg-[#0ABAB5] border border-transparent rounded sm:mt-10 font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              role="button"
              rel="noreferrer"
            >
              Book my appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advice;
