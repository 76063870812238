import React from "react"
import { AiOutlineMessage } from "react-icons/ai"
import { GiLoveInjection, GiMedicines } from "react-icons/gi"
import { RiBattery2ChargeLine } from "react-icons/ri"
import { TbHeartbeat, TbClipboardText } from "react-icons/tb"
import { FaRunning } from "react-icons/fa"
import { RightArrow } from "../../../assets/svg/arrow"

//image
import spineImg from "../../../assets/svg/spine.svg"

import { Link } from "react-router-dom"
// import icon1 from "../../../assets/images/Featured icon.png";

const Services = () => {
    return (
        <div className="py-[70px] !pt-0 w-full sm:w-[90%] lg:w-[75%] mx-auto">
            <h1 className="text-[31px] font-[600] text-center">Services I offer</h1>
            <div
                data-aos="fade-up"
                className="mt-[40px] flex flex-wrap"
            >
                <div
                    style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)" }}
                    className="flex flex-col mt-10 items-center w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[25px] px-[20px] mx-auto"
                >
                    <div className="bg-[#0ABAB5] rounded-full p-2.5 flex justify-center items-center">
                        <AiOutlineMessage
                            color="white"
                            className="w-[50px] h-[50px]"
                        />
                    </div>
                    {/* <img className="w-[70px] h-[70px]" src={icon1} alt="" /> */}
                    <h3 className="font-[600] text-[20px] font-[Roboto] mt-3">Consultation</h3>
                    <p className="text-center text-[#7D7D7D] text-[15px] mt-1 leading-5 font-[Roboto]">
                        Consultation is the core component. As a specialist in Chronic Pain management, I offer
                        comprehensive assessment of the pain condition.
                        {/* The initial consultation is usually long where I get to know you,
            your condition, the impact of pain on you so that we can devise an
            appropriate management plan */}
                    </p>
                    <div className="flex justify-center items-end h-full">
                        <Link
                            to="/consultation"
                            className="mt-6 font-[Roboto] flex text-[#0ABAB5] items-center"
                        >
                            {" "}
                            Read More <RightArrow className="ml-2 !w-5 !h-5" />{" "}
                        </Link>
                    </div>
                </div>
                <div
                    style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)" }}
                    className="flex flex-col mt-10 items-center w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[25px] px-[20px] mx-auto"
                >
                    <div className="bg-[#0ABAB5] rounded-full p-2.5 flex justify-center items-center">
                        {/* <TbHeartbeat
                            color="white"
                            className="w-[50px] h-[50px]"
                        /> */}
                        <img
                            className="w-[50px] h-[50px]"
                            src={spineImg}
                            alt="spine"
                        />
                    </div>
                    <h3 className="font-[600] text-[20px] font-[Roboto] mt-3">Pain Conditions</h3>
                    <p className="text-center text-[#7D7D7D] text-[15px] mt-1 leading-5 font-[Roboto]">
                        Pain conditions range from musculoskeletal, nerve pain to widespread pain.
                        {/* You should be empowered with detailed knowledge about your
            condition so that you can manage it successfully. */}
                    </p>
                    <div className="flex justify-center items-end h-full">
                        <Link
                            className="mt-6 font-[Roboto] flex text-[#0ABAB5] items-center"
                            to="/pain-condition"
                        >
                            Read More <RightArrow className="ml-2 !w-5 !h-5" />{" "}
                        </Link>
                    </div>
                </div>
                <div
                    style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)" }}
                    className="flex flex-col mt-10 items-center w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[25px] px-[20px] mx-auto"
                >
                    <div className="bg-[#0ABAB5] rounded-full p-2.5 flex justify-center items-center">
                        <FaRunning
                            color="white"
                            className="w-[50px] h-[50px]"
                        />
                    </div>
                    <h3 className="font-[600] text-[20px] font-[Roboto] mt-3">Self-management</h3>
                    <p className="text-center text-[#7D7D7D] text-[15px] mt-1 leading-5 font-[Roboto]">
                        Self-management is an essential tool in chronic pain management. A variety of self-management
                        techniques and tools exists.
                    </p>
                    <div className="flex justify-center items-end h-full">
                        <Link
                            className="mt-6 font-[Roboto] flex text-[#0ABAB5] items-center"
                            to="/self-management"
                        >
                            Read More <RightArrow className="ml-2 !w-5 !h-5" />{" "}
                        </Link>
                    </div>
                </div>
                <div
                    style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)" }}
                    className="flex flex-col mt-10 items-center w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[25px] px-[20px] mx-auto"
                >
                    <div className="bg-[#0ABAB5] rounded-full p-2.5 flex justify-center items-center">
                        <TbClipboardText
                            color="white"
                            className="w-[50px] h-[50px]"
                        />
                    </div>
                    <h3 className="font-[600] text-[20px] font-[Roboto] mt-3">Investigations/Referrals</h3>
                    <p className="text-center text-[#7D7D7D] text-[15px] mt-1 leading-5 font-[Roboto]">
                        Pain Management needs multi-disciplinary input. Referrals to physiotherapy, psychology is
                        common.
                        {/* As part of your comprehensive
            assessment, you may be required to have further tests. This may
            include a scan or blood tests. Occasionally, other medical
            specialists input will be necessary so that your root cause of the
            pain is always addressed. */}
                    </p>
                    <div className="flex justify-center items-end h-full">
                        <Link
                            className="mt-6 font-[Roboto] flex text-[#0ABAB5] items-center"
                            to="investigations"
                        >
                            Read More <RightArrow className="ml-2 !w-5 !h-5" />{" "}
                        </Link>
                    </div>
                </div>
                <div
                    style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)" }}
                    className="flex flex-col mt-10 items-center w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[25px] px-[20px] mx-auto"
                >
                    <div className="bg-[#0ABAB5] rounded-full p-2.5 flex justify-center items-center">
                        <GiMedicines
                            color="white"
                            className="w-[50px] h-[50px]"
                        />
                    </div>
                    <h3 className="font-[600] text-[20px] font-[Roboto] mt-3">Pain Medications</h3>
                    <p className="text-center text-[#7D7D7D] text-[15px] mt-1 leading-5 font-[Roboto]">
                        Safe, effective and efficient use of appropriate pain medications is a key to manage your pain
                        successfully
                    </p>
                    <div className="flex justify-center items-end h-full">
                        <Link
                            className="mt-6 font-[Roboto] flex text-[#0ABAB5] items-center"
                            to="/pain-medication"
                        >
                            {" "}
                            Read More <RightArrow className="ml-2 !w-5 !h-5" />{" "}
                        </Link>
                    </div>
                </div>
                <div
                    style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)" }}
                    className="flex flex-col mt-10 items-center w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[25px] px-[20px] mx-auto"
                >
                    <div className="bg-[#0ABAB5] rounded-full p-2.5 flex justify-center items-center">
                        <GiLoveInjection
                            color="white"
                            className="w-[50px] h-[50px]"
                        />
                    </div>
                    <h3 className="font-[600] text-[20px] font-[Roboto] mt-3">Pain Injections</h3>
                    <p className="text-center text-[#7D7D7D] text-[15px] mt-1 leading-5 font-[Roboto]">
                        Pain injections are a recognised tool in the management of pain. They range from steroid
                        injections to muscle, joints, to ablation of nerves to reduce your pain.
                    </p>
                    <div className="flex justify-center items-end h-full">
                        <Link
                            className="mt-6 font-[Roboto] flex text-[#0ABAB5] items-center"
                            to="/pain-injection"
                        >
                            Read More <RightArrow className="ml-2 !w-5 !h-5" />{" "}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
