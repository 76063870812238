import React, { useEffect } from "react"
import Comment from "../../@core/components/Comments"
import Header from "../../@core/components/Header"
import SingalService from "../../@core/components/SingalService"

import img2 from "../../assets/images/about/1.jpg"
import img1 from "../../assets/images/about/2.jpeg"

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
        >
            <h2 className="text-center mt-6 mb-5 font-[Roboto] font-[600] text-[40px]">About Me</h2>
            <div className="max-w-7xl mx-auto px-5">
                <Header page="aboutus" />
                <div className=" flex flex-wrap">
                    <div
                        data-aos="fade-up"
                        className="lg:flex flex-wrap my-[25px] sm:my-[40px] mx-auto"
                    >
                        <div className="w-[90%] lg:hidden sm:w-[70%] lg:w-[45%] mx-auto">
                            <img
                                className="w-full h-full"
                                src={img1}
                                alt=""
                            />
                        </div>
                        <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
                            <p className="text-lg font-[600] text-[#252525] font-[Roboto]">My Background</p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                I am one of the senior consultants' in Pain Medicine in Aberdeen. I have been involved
                                in management of spinal pain, neuropathic pain, abdominal wall pain and spinal cord
                                stimulation for over 15 years. I have access to multidisciplinary team in the private
                                sector that reflects my NHS practice. This includes access to medications for pain
                                management, pain injections, psychological support and pain management physiotherapy. I
                                work closely with neurosurgeons, orthopaedic surgeons, gynaecologists, and general
                                surgeons.
                            </p>
                        </div>
                        <div className="w-[90%] hidden lg:block sm:w-[70%] lg:w-[45%] mx-auto">
                            <img
                                className="w-full h-80"
                                src={img1}
                                alt=""
                            />
                        </div>
                    </div>

                    <div
                        data-aos="fade-up"
                        className="lg:flex items-center flex-wrap my-[25px] sm:my-[40px] mx-auto pb-20"
                    >
                        <div className="w-[90%] sm:w-[70%] lg:w-[45%] mx-auto">
                            <img
                                className="w-full h-52 md:h-80"
                                src={img2}
                                alt=""
                            />
                        </div>
                        <div className="lg:mt-0 mt-3 w-[90%] sm:w-[70%] lg:w-[45%] mx-auto lg:h-full lg:flex lg:flex-col lg:justify-center">
                            <p className="text-lg font-[600] text-[#252525] font-[Roboto]">My Education</p>
                            <p className="text-start mt-3 text-[14px] lg:text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3">
                                I did my undergraduate medical training at Coimbatore Medical College and graduated from
                                the Tamilnadu DR MGR Medical University in 1993. The postgraduate training in
                                Anaesthesia was based at the Grant Medical College, Mumbai. I completed my MD at
                                University of Mumbai. Then, I came to UK to pursue further education and specialist
                                training. I did my SHO rotation in Russell Hall Hospital, Dudley and University hospital
                                of Wales, Cardiff. I thereafter completed 5 years of registrar training based in Queen
                                Elizabeth Medical Centre, Birmingham before obtaining the Certificate of Completion of
                                Training. During this time, I passed FRCA (Fellowship of Royal College of Anaesthetists)
                                in my first attempt. I also completed Specialist training in Pain Medicine at Walton
                                Centre for Neurology & Neurosurgery, Liverpool in 2006. I was awarded the FFPMRCA
                                (Fellowship of Faculty of Pain Medicine) by assessment. I have been a consultant since
                                2007.
                            </p>
                        </div>
                    </div>
                </div>

                <h2 className="font-[600] font-[Roboto] text-center text-[#1F3636] text-[31px]">My Publications</h2>

                <div
                    data-aos="fade-up"
                    className="mt-[40px] flex flex-wrap pb-16"
                >
                    <SingalService
                        title={"Abdominal Cutaneous Nerve Entrapment Syndrome"}
                        pubDate={"2014 - Published"}
                        directLink
                        link={"https://doi.org/10.1093/bjaceaccp/mku016"}
                    />
                    <SingalService
                        pubDate={"2008 - Published"}
                        title={"Lumbar Zygapophyseal (Facet) Joint Pain"}
                        directLink
                        link={"https://doi.org/10.1177/204946370800200103"}
                    />
                    <SingalService
                        pubDate={"2013 - Published"}
                        title={"Radiofrequency Techniques in Pain Management"}
                        directLink
                        link={"https://doi.org/10.1016/j.mpaic.2013.09.011"}
                    />
                    <a
                        className=" mt-16 text-lg mx-auto underline"
                        href="https://www.abdn.ac.uk/people/saravanakumark#publications"
                    >
                        Read my full publications list
                    </a>
                </div>

                <Comment />
            </div>
        </div>
    )
}

export default About
