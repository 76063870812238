import ServiceDetails from "../../@core/components/ServiceDetails";
import About from "../../pages/About";
import Connect from "../../pages/Connect";
import Home from "../../pages/Home";
import Patients from "../../pages/Patients/Patients";
import Consultation from "../../pages/Services/Consultation";
import Investigations from "../../pages/Services/Investigations";
import PainConditions from "../../pages/Services/PainConditions";
import PainInjections from "../../pages/Services/PainInjections";
import PainMedications from "../../pages/Services/PainMedications";
import SelfManagement from "../../pages/Services/SelfManagement";

const pages = [
  {
    path: "/",
    element: <Home />,
    meta: {},
  },
  {
    path: "/consultation",
    element: <Consultation />,
    meta: {},
  },
  {
    path: "/investigations",
    element: <Investigations />,
    meta: {},
  },
  {
    path: "/self-management",
    element: <SelfManagement />,
    meta: {},
  },
  {
    path: "/pain-medication",
    element: <PainMedications />,
    meta: {},
  },
  {
    path: "/pain-injection",
    element: <PainInjections />,
    meta: {},
  },
  {
    path: "/pain-condition",
    element: <PainConditions />,
    meta: {},
  },
  {
    path: "/patients",
    element: <Patients />,
    meta: {},
  },
  {
    path: "/about",
    element: <About />,
    meta: {},
  },
  {
    path: "/contact",
    element: <Connect />,
    meta: {},
  },
  {
    path: "/pain-injection/:id",
    element: <ServiceDetails />,
    meta: {
      isNotMenu: false,
      isNotAdvice: true,
      isNotFooter: false,
    },
  },
  {
    path: "/pain-condition/:id",
    element: <ServiceDetails />,
    meta: {
      isNotMenu: false,
      isNotAdvice: true,
      isNotFooter: false,
    },
  },
  {
    path: "/pain-medication/:id",
    element: <ServiceDetails />,
    meta: {
      isNotMenu: false,
      isNotAdvice: true,
      isNotFooter: false,
    },
  },
  {
    path: "/self-management/:id",
    element: <ServiceDetails />,
    meta: {
      isNotMenu: false,
      isNotAdvice: true,
      isNotFooter: false,
    },
  },
];

export default pages;
