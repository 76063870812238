import React, { useState } from "react"

import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react"

const Question = () => {
    const [open, setOpen] = useState(1)

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value)
    }

    // custom styles for animation
    const customAnimation = {
        mount: { scale: 1 },
        unmount: { scale: 0.9 }
    }

    const data = [
        {
            id: 1,
            title: "Q. I have been referred by a specialist. How do I book an appointment?",
            content:
                "The GP referrals team at Albyn hospital will automatically give you the next available appointment. If you can change the time and date by ring them on 0808 189 5654 or email them. Morning and you should plan on being at the hospital for at least 4 hours."
        },
        {
            id: 2,
            title: "Q. When will I receive my clinic letter following my appointment?",
            content:
                "As part of registration process, you will be enrolled in carebit platform so that you can access your clinic letters securely. This is a secure platform used by many doctors for appointment, clinic letters as well as invoicing. I use it mainly for secure clinic letters and invoicing. I aim to upload your clinic letter to you within 4 days of your appointment and often you will receive it much sooner, however on some occasions clinic letters may take up to 7 days to reach you."
        },
        {
            id: 3,
            title: "Q. Will I be sedated for a day case procedure?",
            content:
                "Yes. An independent Consultant Anaesthetist provides the sedation services. They will provide conscious sedation for the pain procedure. This link gives you more information about sedation:"
        },
        {
            id: 4,
            title: "Q. Do you offer medicinal cannabis?",
            content: "No"
        },
        {
            id: 5,
            title: "Q. How soon can I fly following a Day case procedure?",
            content:
                "There is no specific time frame. I recommend avoiding short haul flights for 24 hours and long haul flights for 7 days. This is due to factors such as sedation for the procedure, post-procedure pain and access to emergency medical services."
        },
        {
            id: 6,
            title: "Q. I’m still in a lot of pain after my injection last week, is this normal?",
            content:
                "Some procedures take more time to heal than others. Post-procedure bruising and pain should settle within two weeks and may need additional painkillers. It is important that you continue with your usual pain medications till the benefits are noticeable."
        },
        {
            id: 7,
            title: "Q. Will I need to have a procedure repeated?",
            content:
                "It depends on the individual circumstances. In general, repeated steroid injections are not advisable. The need, benefits and risks of repeat procedure should be evaluated ongoing basis."
        }
    ]

    function Icon({ id, open }) {
        return (
            <>
                {id === open ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 font-bold text-[#A69167]"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 12h-15"
                        />
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 font-bold text-[#A69167]"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                        />
                    </svg>
                )}
            </>
        )
    }
    return (
        <div className="py-[40px] md:py-[70px]">
            <section className="py-10 bg-white sm:py-20 !pb-10">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div className="max-w-2xl mx-auto text-center">
                        <h1 className="text-[31px] font-[600] text-center font-[Roboto]">Frequently asked questions</h1>
                    </div>

                    <div className="max-w-4xl mx-auto mt-8 space-y-4 md:mt-10">
                        {data.map((i) => (
                            <div
                                data-aos="fade-up"
                                key={i.id}
                                className="relative inline-flex w-full mt-1 group"
                            >
                                <div className="absolute transitiona-all duration-1000 opacity-20 -inset-px bg-gradient-to-r from-[#44beff27] via-[#ff44ec1f] to-[#ff665e21] rounded-xl blur-lg group-hover:opacity-80 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
                                <div className="w-full transition-all duration-200 bg-white border border-gray-200 rounded-lg cursor-pointer hover:bg-gray-50">
                                    <Accordion
                                        open={open === i.id}
                                        className="relative w-full transition-all duration-200 bg-white rounded-lg"
                                        animate={customAnimation}
                                        icon={
                                            <Icon
                                                id={i.id}
                                                open={open}
                                            />
                                        }
                                    >
                                        <AccordionHeader
                                            className="flex px-5 text-base font-semibold text-left text-black sm:text-lg second_Head_content"
                                            onClick={() => handleOpen(i.id)}
                                        >
                                            {i.title}
                                        </AccordionHeader>
                                        {i.content.includes("email") ? (
                                            <AccordionBody className="px-4 !pt-0 !pb-5 sm:px-6">
                                                {i.content.substring(0, i.content.indexOf("email"))}
                                                <a
                                                    className="text-blue-400 underline "
                                                    href="mailto:gpreferrals_albyn@circlehealthgroup.co.uk"
                                                >
                                                    emailing
                                                </a>
                                                {i.content.substring(i.content.indexOf("email") + 5, i.content.length)}
                                            </AccordionBody>
                                        ) : i.id === 3 ? (
                                            <AccordionBody className="px-4 !pt-0 !pb-5 sm:px-6">
                                                {i.content}{" "}
                                                <a
                                                    className="text-blue-400 underline "
                                                    href="https://rcoa.ac.uk/patient-information/patient-information-resources/information-sedation"
                                                >
                                                    https://rcoa.ac.uk/patient-information/patient-information-resources/information-sedation
                                                </a>
                                            </AccordionBody>
                                        ) : (
                                            <AccordionBody className="px-4 !pt-0 !pb-5 sm:px-6">
                                                {i.content}
                                            </AccordionBody>
                                        )}
                                    </Accordion>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Question
