import React, { useEffect } from "react"

//images
import { GiLoveInjection, GiTransparentTubes } from "react-icons/gi"
import bg from "../../assets/images/bg.png"
import { RightArrow } from "../../assets/svg/arrow"

//image
import { TbHeartbeat } from "react-icons/tb"
import { Link } from "react-router-dom"

const Patients = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
        >
            <div
                style={{ backgroundImage: `url(${bg})` }}
                className="w-full flex flex-col items-center py-[120px] px-[30px]"
            >
                <h1 className="font-[Roboto] font-[700] text-white ms:!text-[48px] text-[30px]">Patients</h1>
                <p className="text-white text-[18px] ms:text-[16px] text-center">
                    Empowering you to successfully manage the chronic pain.
                </p>
            </div>

            <div className="py-[40px] md:py-[75px] px-5 max-w-7xl mx-auto">
                <div className="grid items-stretch gap-y-10 lg:grid-cols-2 lg:gap-x-20">
                    <div className="relative mt-10 md:mt-0">
                        {/* <div className="overflow-hidden aspect-w-3 aspect-h-4">
              <img className="w-full" src={img1} alt="" />
            </div>

            <div className="relative">
              <div className="h-full overflow-hidden aspect-w-3 aspect-h-4">
                <img className="w-full" src={img2} alt="" />
              </div>

              <div className="absolute inset-0 grid w-full h-full place-items-center">
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-12 h-12 text-blue-600 bg-white rounded-full shadow-md lg:w-20 lg:h-20"
                >
                  <svg
                    className="w-6 h-6 lg:w-8 lg:h-8"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                  </svg>
                </button>
              </div>
            </div> */}
                        <iframe
                            src="https://www.youtube.com/embed/jIwn9rC3rOI?controls=0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            className="w-full h-80 rounded-lg"
                            allowFullScreen
                        ></iframe>
                    </div>

                    <div className="flex flex-col items-start xl:px-16 justify-between">
                        <div>
                            <h2 className="text-3xl font-[600] font-[Roboto] leading-tight text-black sm:text-4xl lg:text-4xl">
                                Understanding Pain
                            </h2>
                            <p className="mt-4 text-base leading-relaxed text-gray-600">
                                This animated video from the Hunter Integrated Pain Service, University of South
                                Australia, and University of Washington gives an opportunity to learn more about chronic
                                pain and its management. Though the video originated few years ago, the principles are
                                applicable even, today.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-20 py-[30px] lg:py-[110px]">
                    <div className="block mt-6 md:hidden sm:pr-0 w-full">
                        <div className="relative mx-auto rounded-lg">
                            {/* <img className="" src={img4} alt="" />

              <img
                className="absolute origin-bottom-right scale-75 rounded-md -bottom-12 -right-12"
                src={img3}
                alt=""
              /> */}
                            <iframe
                                src="https://www.youtube.com/embed/ac13iuGByRQ?controls=0"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                                className="w-full h-80 rounded-lg"
                            ></iframe>
                        </div>
                    </div>

                    <div className="flex flex-col items-center">
                        <div className="lg:w-[80%]">
                            <h2 className="text-3xl font-[600] font-[Roboto] leading-tight text-black sm:text-4xl lg:text-5xl">
                                Self Management
                            </h2>
                            <p className="mt-4 text-base leading-relaxed text-gray-600">
                                This animated video introduces to the principles of self-management and how it can
                                support people managing long term conditions. Chronic pain is one of the long-term
                                conditions and is relevant for its management.
                            </p>
                        </div>
                    </div>

                    <div className="hidden md:block pr-12 sm:pr-0 w-full">
                        <div className="w-full mb-12 mx-auto rounded-lg">
                            {/* <img className="" src={img4} alt="" />

              <img
                className="absolute origin-bottom-right scale-75 rounded-md -bottom-12 -right-12"
                src={img3}
                alt=""
              /> */}
                            <iframe
                                src="https://www.youtube.com/embed/ac13iuGByRQ?controls=0"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                                className="w-full h-80 rounded-lg"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className="grid items-stretch gap-y-10 lg:grid-cols-2 lg:gap-x-20">
                    <div className="relative mt-10 md:mt-0">
                        {/* <div className="overflow-hidden aspect-w-3 aspect-h-4">
              <img className="w-full" src={img1} alt="" />
            </div>

            <div className="relative">
              <div className="h-full overflow-hidden aspect-w-3 aspect-h-4">
                <img className="w-full" src={img2} alt="" />
              </div>

              <div className="absolute inset-0 grid w-full h-full place-items-center">
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-12 h-12 text-blue-600 bg-white rounded-full shadow-md lg:w-20 lg:h-20"
                >
                  <svg
                    className="w-6 h-6 lg:w-8 lg:h-8"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                  </svg>
                </button>
              </div>
            </div> */}
                        <iframe
                            src="https://www.youtube.com/embed/jIwn9rC3rOI?controls=0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            className="w-full h-80 rounded-lg"
                            allowFullScreen
                        ></iframe>
                    </div>

                    <div className="flex flex-col items-start xl:px-16 justify-between">
                        <div>
                            <h2 className="text-3xl font-[600] font-[Roboto] leading-tight text-black sm:text-4xl lg:text-4xl">
                                Types of pain
                            </h2>
                            <p className="mt-4 text-base leading-relaxed text-gray-600">
                                This animated pain gives you information about the different types of pain.
                            </p>
                        </div>
                    </div>
                </div>

                {/* <h2 className="font-[600] font-[Roboto] text-center text-[#1F3636] text-[31px] pb-8">
          Lorum Ipsum
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-24">
          <div className="relative overflow-hidden rounded-2xl lg:rounded-3xl">
            <img
              className="object-cover w-full h-full transition-all duration-200 group-hover:scale-110"
              src="https://landingfoliocom.imgix.net/store/collection/saasui/images/testimonial/1/woman-smiling.png"
              alt=""
            />
            <div className="absolute inset-0 pointer-events-none bg-gradient-to-t from-gray-900 via-transparent to-transparent"></div>
            <div className="absolute bottom-0 left-0 px-8 py-6">
              <p className="text-lg font-semibold text-white">Albert Flores</p>
              <p className="text-sm font-normal text-gray-400">Patient</p>
            </div>
          </div>

          <div className="h-full flex flex-col justify-center">
            <div className="relative">
              <svg
                className="w-auto text-gray-300 h-9 left-0 top-[-40px] absolute"
                viewBox="0 0 43 35"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M42.28 34.3H26.04C24.4533 29.1667 23.66 23.8467 23.66 18.34C23.66 12.74 25.1067 8.30666 28 5.03999C30.9867 1.68 35.3733 0 41.16 0V7.84C36.4933 7.84 34.16 10.6867 34.16 16.38V19.04H42.28V34.3ZM18.62 34.3H2.38C0.793333 29.1667 0 23.8467 0 18.34C0 12.74 1.44667 8.30666 4.34 5.03999C7.32667 1.68 11.7133 0 17.5 0V7.84C12.8333 7.84 10.5 10.6867 10.5 16.38V19.04H18.62V34.3Z" />
              </svg>
              <blockquote className="mt-8">
                <p className="text-2xl font-medium leading-snug tracking-tight text-gray-900 sm:text-3xl">
                  “Amet minim mollit non deserunt ullamco est sit aliqua dolor
                  do amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet.”
                </p>
                <p className="mt-8 text-lg font-normal text-gray-600 sm:text-xl">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet.
                </p>
              </blockquote>
            </div>
          </div>
        </div>

        <h2 className="mt-24 font-[600] font-[Roboto] text-center text-[#1F3636] text-[31px] pb-8">
          Lorum Ipsum
        </h2>
        <div className="grid items-center grid-cols-1 lg:grid-cols-2 lg:gap-x-16 xl:gap-x-24 gap-y-12">
          <div className="grid overflow-hidden lg:order-2 rounded-3xl place-items-center">
            <div className="p-6 sm:p-12">
              <img className="w-full" src={img5} alt="" />
            </div>
          </div>

          <div className="xl:pr-24 lg:order-1">
            <ul className="space-y-10 sm:space-y-14">
              <li className="flex items-start">
                <p className="ml-3 text-lg font-normal text-gray-900">
                  <span className="font-semibold text-xl">Velit officia :</span>{" "}
                  “Amet minim mollit non deserunt ullamco est sit aliqua dolor
                  do amet sint. Velit officia consequat duis enim velit mollit.”
                </p>
              </li>

              <li className="flex items-start">
                <p className="ml-3 text-lg font-normal text-gray-900">
                  <span className="font-semibold text-xl">Velit officia :</span>{" "}
                  “Amet minim mollit non deserunt ullamco est sit aliqua dolor
                  do amet sint. Velit officia consequat duis enim velit mollit.”
                </p>
              </li>

              <li className="flex items-start">
                <p className="ml-3 text-lg font-normal text-gray-900">
                  <span className="font-semibold text-xl">Velit officia :</span>{" "}
                  “Amet minim mollit non deserunt ullamco est sit aliqua dolor
                  do amet sint. Velit officia consequat duis enim velit mollit.”
                </p>
              </li>
            </ul>
          </div>
        </div>

        <h2 className="mt-24 font-[600] font-[Roboto] text-center text-[#1F3636] text-[31px] pb-8">
          Lorum Ipsum
        </h2>
        <div className="grid max-w-md grid-cols-1 gap-5 mx-auto mt-12 lg:grid-cols-3 sm:mt-16 sm:gap-6 lg:gap-8 lg:max-w-none">
          <div className="bg-[#E7FFFE] rounded-2xl">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="px-4 pt-2 text-xl font-semibold text-gray-900">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint.
              </h3>
              <img
                className="object-cover mt-5 rounded-xl w-full"
                src={img6}
                alt=""
              />
            </div>
          </div>

          <div className="bg-[#E7FFFE] rounded-2xl">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="px-4 pt-2 text-xl font-semibold text-gray-900">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint.
              </h3>
              <img
                className="object-cover mt-5 rounded-xl w-full"
                src={img7}
                alt=""
              />
            </div>
          </div>

          <div className="bg-[#E7FFFE] rounded-2xl">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="px-4 pt-2 text-xl font-semibold text-gray-900">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint.
              </h3>
              <img
                className="object-cover mt-5 rounded-xl w-full"
                src={img8}
                alt=""
              />
            </div>
          </div>
        </div> */}
                <div
                    data-aos="fade-up"
                    className="mt-[40px] flex flex-wrap"
                >
                    <div
                        style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)" }}
                        className="flex flex-col mt-10 items-center w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[25px] px-[20px] mx-auto"
                    >
                        <div className="bg-[#0ABAB5] rounded-full p-2.5 flex justify-center items-center">
                            <TbHeartbeat
                                color="white"
                                className="w-[50px] h-[50px]"
                            />
                        </div>
                        <h3 className="font-[600] text-[20px] font-[Roboto] mt-3">Pain Conditions</h3>
                        <p className="text-center text-[#7D7D7D] text-[15px] mt-1 leading-5 font-[Roboto]">
                            Pain conditions range from musculoskeletal, nerve pain to widespread pain.
                            {/* You should be empowered with detailed knowledge about your
            condition so that you can manage it successfully. */}
                        </p>
                        <div className="flex justify-center items-end h-full">
                            <Link
                                className="mt-6 font-[Roboto] flex text-[#0ABAB5] items-center"
                                to="/pain-condition"
                            >
                                Read More <RightArrow className="ml-2 !w-5 !h-5" />{" "}
                            </Link>
                        </div>
                    </div>

                    <div
                        style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)" }}
                        className="flex flex-col mt-10 items-center w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[25px] px-[20px] mx-auto"
                    >
                        <div className="bg-[#0ABAB5] rounded-full p-2.5 flex justify-center items-center">
                            <GiTransparentTubes
                                color="white"
                                className="w-[50px] h-[50px]"
                            />
                        </div>
                        <h3 className="font-[600] text-[20px] font-[Roboto] mt-3">Pain Medications</h3>
                        <p className="text-center text-[#7D7D7D] text-[15px] mt-1 leading-5 font-[Roboto]">
                            Safe, effective and efficient use of appropriate pain medication to your condition is a key
                            to manage your pain successfully.
                        </p>
                        <div className="flex justify-center items-end h-full">
                            <Link
                                className="mt-6 font-[Roboto] flex text-[#0ABAB5] items-center"
                                to="/pain-medication"
                            >
                                {" "}
                                Read More <RightArrow className="ml-2 !w-5 !h-5" />{" "}
                            </Link>
                        </div>
                    </div>
                    <div
                        style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.05)" }}
                        className="flex flex-col mt-10 items-center w-[80%] xs:w-[60%] ms:w-[45%] md:w-[31%] py-[25px] px-[20px] mx-auto"
                    >
                        <div className="bg-[#0ABAB5] rounded-full p-2.5 flex justify-center items-center">
                            <GiLoveInjection
                                color="white"
                                className="w-[50px] h-[50px]"
                            />
                        </div>
                        <h3 className="font-[600] text-[20px] font-[Roboto] mt-3">Pain Injections</h3>
                        <p className="text-center text-[#7D7D7D] text-[15px] mt-1 leading-5 font-[Roboto]">
                            Pain injections are a recognised tool in the management of pain. They range from steroid
                            injections to muscle, joints, to ablation of nerves to reduce your pain.
                        </p>
                        <div className="flex justify-center items-end h-full">
                            <Link
                                className="mt-6 font-[Roboto] flex text-[#0ABAB5] items-center"
                                to="/pain-injection"
                            >
                                Read More <RightArrow className="ml-2 !w-5 !h-5" />{" "}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Patients
