import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import mediumZoom from "medium-zoom";
import axios from "../../../axios";

const ServiceDetails = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const exactPath = pathname.split("/")[1];
  const [serviceInfo, setServiceInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const getData = async () => {
      setLoading(true); // Start loading before API call
      const { data } = await axios.get(`/${exactPath}/get/${id}`);
      setServiceInfo(data.data);
      setLoading(false); // End loading after data is set
    };
    getData();
  }, [id, exactPath]);

  useEffect(() => {
    if (serviceInfo) {
      const images = document.querySelectorAll(".blog-post img");
      const zoom = mediumZoom(images, {
        margin: 20,
        background: "#03001c18",
        scrollOffset: 0,
      });

      return () => zoom.detach();
    }
  }, [serviceInfo]);

  return (
    <div data-aos="fade-up" className="min-h-[70vh] mt-2">
      {loading ? (
        // Loading placeholder
        <div className="w-full xs:w-[98%] sm:w-[95%] mx-auto flex flex-col overflow-hidden transition-all duration-200 transform bg-white group animate-pulse">
          <div className="w-full h-64 p-3 bg-gray-300"></div>
          <div className="flex-1 px-4 py-5 space-y-3 sm:p-6">
            <div className="w-3/4 h-4 bg-gray-300 rounded"></div>
            <div className="h-4 bg-gray-300 rounded"></div>
            <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
            <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
            <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
            <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
          </div>
        </div>
      ) : (
        // Existing component content
        <div className="blog-container w-full xs:w-[98%] sm:w-[95%] mx-auto flex flex-col overflow-hidden transition-all duration-200 transform bg-white group">
          <img
            className="object-cover w-full h-full p-3"
            src={serviceInfo?.thumbnail}
            alt=""
          />
          <div className="flex-1 px-4 py-5 sm:p-6">
            <p className="blog-title blog-header text-lg font-[600] text-[#252525] font-[Roboto]">
              {serviceInfo?.title}
            </p>
            <div
              dangerouslySetInnerHTML={{ __html: serviceInfo?.content }}
              className="blog-post mt-3 text-[16px] font-normal leading-6 font-[Roboto] text-[#7D7D7D] line-clamp-3"
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceDetails;
